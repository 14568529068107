.container{
    display:flex;
    flex-direction: column;
    height:100%;
}
.container > div:last-child{
    flex-grow:1;
    display:flex;
    flex-direction: column;
    overflow:hidden;
    height:100%;
}

/* For Tab Pane Content */
.container > div:last-child > div:last-child{
    display:flex;
    flex-direction: column;
    height:100%;
}