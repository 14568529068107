.mainWrapper{
    display: flex;
    flex-direction: column;
    height: 30vh;
    padding: 0 1em;
}

.header{
    display: flex;
    height: 30%;
}

.headerLeft{
    display: flex;
    width: 50%;
}

.headerRight{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
    color: rgba(86, 86, 86, 1); 
}
.body{
    display: flex;
    flex-direction: column;
    height: 70%;
    padding: 1em 0;
    overflow: auto;
}

.bodyTitle{
    /* color: rgba(55, 163, 134, 1); */
    font-size: 2vh;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: dimgray;
}

.bodyDescription{
    color: rgba(90, 90, 90, 1);
    font-size: 1.5vh;
}

.bodyControls{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.5em;
}

.inputContainer{
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.inputContainer > span{
    min-width: 15%;
    font-size: 12px;
    color: #5A5A5A;
    font-weight: bold;
}

.secretKeyContainer{
    width: 85%;
}

.textboxStyle{
    width: 100%;
}

.inputToken{
    display: flex;
    width: 84%;
}


.test{
    display: flex;     
}

/* .bodyFooter > span{
    color: rgba(90, 90, 90, 0.75);
    font-size: 10px;
} */