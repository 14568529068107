.module
{
    width:30em;
    margin:auto;
    display:flex;
    flex-direction:column;
    border:1px solid gray;
    padding:1em;
    border-radius:1em;
    background-color: white;
    margin-top:5em;
}

.module > div{
    margin-bottom:2em;
}

.module > :first-child{
    display:flex;
    align-items:center;
    font-weight:bold;
    font-size:15pt;
}