.row{
    display:flex;
    flex-direction:row;
    border-bottom:1px solid lightgray;
}
.row > * {
    width:100%;
    margin:5px;
}

.buttons {
    display:flex;
    flex-direction: row;
}
.buttons > * {
    width:fit-content
}
.buttons i {
    font-size:1em !important;
}