.module{
    display:flex;
    flex-direction: column;
    height:100%
}
.module > div:last-child{
    flex-grow:1;
    height:100%;
    overflow-y:auto;
    overflow-x:hidden;
}

.module > div:last-child > div:first-child{
    margin-top:0.5em;
}