.NotificationPopControl_row{
    border-radius: 1em;
    padding:10px;
    margin: 5px;
    font-size:12px;
    cursor:pointer;
    background:#f3f3f3;

}
.NotificationPopControl_unread{
    border-radius: 1em;
    padding:10px;
    margin: 5px;
    font-size:12px;
    cursor:pointer;
    background:#edf2fa
}

.NotificationPopControl_cell{
    display:table-cell;
    vertical-align:middle;
    font-size:10px;
}

.NotificationPopControl_row:hover{
    background:lightgray;
}
.NotificationPopControl_unread:hover{
    background:lightgray;
}

.NotificationPopControl_desc{
    width:400px;
}

.NotificationPopControl_avatar{
    padding:5px;
    width:50px;
}

.NotificationPopControl_menu{
    width:500px;
}

.ChangePasswordFormGroupLabel
{
    display:inline-block;
    width:150px;
}

.ModuleMenu_header{
    border-bottom:1px solid #dddfe2;
    padding-top: 10px;
}

.ModuleMenu_row{
    padding:5px;
    cursor:pointer;
}

.ModuleMenu_cell{
    display:table-cell;
    vertical-align:middle;
}

.ModuleMenu_row:hover{
    background:lightgray;
}

.ModuleMenu_row:hover * .ModuleMenu_starOutline{
    visibility:visible;
}

.ModuleMenu_starOutline
{
    visibility:hidden;
}

.ModuleMenu_desc{
    width:400px;
}

.ModuleMenu_icon{
    padding:5px;
    width:50px;
}

.ModuleMenu_fav{
    width: 32px;
    margin-top: 10px;
}

.ModuleMenu_menu{
    width:300px;
}

.ModuleMenu_viewBox{
    overflow-y: scroll;
    padding:10px;
    width:100%;
    height:470px;
}

.ModuleMenu_popup{
    height: 500px;
    left: 150px;
    top: 50px;
    padding:10px;
}


