.main-wrapper{
    display: flex;
    width: 100%;
    height: 55vh;
}

.left-wrapper{
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-end;
    align-items: center;
}
.left-wrapper label{
    font-size: 1.75vw;
    font-weight: bolder;
}
.left-wrapper span{
    font-size: 14px;
    text-align: center;
    margin: 1em 0;
}
.header-label{
    color: darkgreen;
}

.link-wrapper{
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;
}
.qr-container{
    display: flex;
    flex-direction: column;
    height: 70%;
    align-items: center;
}
.qr-container> img{
    height: 100%;
    width: 50%;
    object-fit: contain;
}
.link-container{
    display: flex;
    flex-direction: column;
    height: 30%;
    justify-content: center;
}

.link-container > div{
    display: flex;
    justify-content: center;
}
.link-container > div >img{
    cursor: pointer;
}
.google-play{
    width: 30%;
    padding-right:  1em;
    object-fit: contain;
}

.app-store{
    width: 26%;
    object-fit: contain;
}

.subtitle{
    font-size: 12px !important;
    text-align: center;
    margin: 0.5em 0;
}

.right-wrapper{
    display: flex;
    width: 50%;
}

.rc-background{
    width: 100%;
    object-fit: contain;
}