.holiday-display {
    height: 100%;
    width: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    font-size: 20px;
    color: #A9A9A9
}

.restday-display {
    height: 100%;
    display: flex;
    align-items: flex-end;
}