.module {
    display:flex;
    flex-direction: column;
    height:100%
}
.content{
    flex-grow:1;
    overflow-y:auto;
    overflow-x:hidden;
    padding:0.5em;
}