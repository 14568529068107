.module{
    display:flex;
    flex-direction: column;
    height:100%;
    overflow:auto;
}

.table_roundup{
    display:flex;
    flex-direction: column;
    min-height:15em;
    width:fit-content;
    border:1px solid lightgray;
    padding:0.2em;
    margin-top:0.5em;
}
.table_roundup_header{
    display:flex;
    flex-direction:row;
    background:lightgray;
    font-weight:bold;
}
.table_roundup_header > div {
    min-width:10em;
    margin:5px;
}
.table_roundup_content{
    display:flex;
    flex-direction:column
}