.main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.main>div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.main>div:last-child>div:last-child {
    flex-grow: 1;
    height: calc(100vh - 60px);
}

.sideMenu {
    overflow: hidden auto;
    background: #252525;
    height: calc(100vh - 60px);
    border-radius:0 !important;
    margin:0 !important;
    min-width:18em;
    box-sizing:content-box;
}


.menuItem {
    width: fit-content;
}

.menuUser>div:first-child {
    padding-right: 5px;
}

.notificationButton {
    outline:none;
    height:100%;
    padding:1em;
    align-items:center;
    cursor:pointer;
    display:flex;
}
.notificationButton:hover,
.notificationButton:focus-visible
{
    background:rgba(255,255,255,0.1);
}
.notificationButton[data-active='true']{
    background: white;
}