.container{
    display:flex;
    height:100%;
    flex-direction: column;
}
.container > div:last-child{
    flex-grow:1;
}


.tabContainer {
    display:flex;
    flex-direction:column;
}

.tabContainer > div:last-child{
    flex-grow:1;
}
.payroll{
    height:100%;
    overflow:auto;
}

.payHeader{
    display: flex;
    align-items: center;
    margin: 0.25em 0 0 0.25em;
    font-size: 22px;
    font-weight: 600;
    color: rgb(96, 96, 96);
}


.payHeader > label{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.25em;
    color: white;
    background-color: green;
    padding: 0 1.2em 0 1em;
    border-radius: 5px;
    font-size: 11px;
    margin-left: 0.5%;
}



.companyHeader{
    display: flex;
    font-weight: 600;
    color: olivedrab;
    font-size: 15px;
    margin-left: 0.25em;
}

.hideWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40vh;
    overflow: auto;
    margin: 0.5em 0;
}
    .hideWrapper > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
    }
