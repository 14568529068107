.container{
    display:flex;
    height:100%;
    flex-direction: column;
}
.container > div:last-child{
    flex-grow:1;
    overflow:hidden auto;
    padding:0.5em;

}