.module {
    display:flex;
    flex-direction:column;
    height:100%;
    padding: 0 0.5em;
}

.headerLabel{
    display: flex;
    align-items: center;
    color: #606060;
    height: 5%;
}

.controlContainer{
    display: flex;
    height: 5%;
}

.leftHeader{
    display: flex;
    width: 20%;
    height: 100%;
    align-items: center;
}

.rightHeader{
    display: flex;
    width: 80%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.paginationContainer{
    display: flex;
    justify-content: flex-end;
}

.bodyWrapper{
    display: flex;
}

.tableDesign{
    background-color: gainsboro;
    font-size: 11px;
    padding: 0px;
}

.popupContainer{
    display: flex;
    flex-direction: column;
}

.columns{
    display: flex;
    padding-bottom: 0.5em;
}

.columns > span{
    display: flex;
    align-items: center;
    width: 30%;
}

.columns > div{
    width: 70%;
}

.noMarginNoPadding {
    margin: 0px;
    padding: 0px;
}

.inputProperty{
    min-width: 50px;
    width: 100%;
}

.textAreaStyle {
   color:  rgba(0,0,0,.87);
   min-width: 50px;
   width: 100%;
   border-color: lightgray;
}

.required{
    background-color: '#FFF6F6';
    border-color: '#E0B4B4';
    color: '#9F3A38';
    -webkit-box-shadow: 'none';
    box-shadow: 'none';
}