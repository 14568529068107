.table{
    display:flex;
    flex-direction: column;
    min-height:20em;
    border:1px solid lightgray;
    padding:0.2em;
    margin-top:0.5em;
}
.table_header{
    display:flex;
    flex-direction:row;
    background:lightgray;
    font-weight:bold;
}
.table_content{
    display:flex;
    flex-direction:column
}
.row {
    display:flex;
    flex-direction:row;
}
.row > div{
    width:8em;
}
.table_header > div{
    width:8em;
    margin-right:1em;
}


