.module {
    display:flex;
    flex-direction:column;
    height:100%;
    padding: 0 0.5em;
}

.headerLabel{
    display: flex;
    align-items: center;
    color: #606060;
    height: 5%;
}

.controlContainer{
    display: flex;
    height: 5%;
}

.leftHeader{
    display: flex;
    width: 20%;
    height: 100%;
    align-items: center;
}

.rightHeader{
    display: flex;
    width: 80%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.paginationContainer{
    display: flex;
    height: 5%;
    justify-content: flex-end;
}

.bodyWrapper{
    display: flex;
}

.tableDesign{
    background-color: gainsboro;
    font-size: 11px;
    padding: 0px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.empDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column{
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    width: 30%;
}

.optionContainer{
    display: flex;
}

.addContainer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 70%;
}

.tableContainer{
    display: flex;

    margin-top: 0.5em;
    /* max-height: 30vh;
    overflow: auto; */
}

.employeeContainer{
    display: flex;
    flex-direction: column;
}

.employeeContainer > span{
    font-size: 12px;
    color: gray;

}

.employeeContainer > label{
    font-weight: bold;
    font-size: 14px;
    padding: 0.1em 0;
}
