.Dashboard_favorite
{
    display:inline-table;
    margin:5px;
    cursor:pointer;
    width:120px;
}

.Dashboard_favorite:hover{
    background:white;
    box-shadow:4px 4px 10px 3px #dddddd;
}

.Dashboard_favoriteCaption{
    text-align:center;
    height:50px;   
    padding:0px 3px 0px 3px;
}

.Dashboard_favoriteIcon{
    margin:auto;
}

