.module{
    display:flex;
    flex-direction: column;
    height:100%;
}
.main{
    flex-grow:1;
    display:flex;
    height:100%;
}

.main > div:first-child{
    width: "200px";
    padding-right: "10px";
    border-right: "1px gray solid";
}

.menuButton {
    display:inline-block;
}

.content {
    display:flex;
    flex-direction:column;
    flex-grow:1;
}
.content > div:last-child{
    flex-grow:1;
    overflow-y:auto;
    overflow-x:hidden;
    padding:0.5em;
    height:0px;
}