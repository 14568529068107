.w3-container:after, .w3-container:before, .w3-panel:after, .w3-panel:before, .w3-row:after, .w3-row:before, .w3-row-padding:after, .w3-row-padding:before, .w3-cell-row:before, .w3-cell-row:after,
.w3-topnav:after, .w3-topnav:before, .w3-clear:after, .w3-clear:before, .w3-btn-group:before, .w3-btn-group:after, .w3-btn-bar:before, .w3-btn-bar:after, .w3-bar:before, .w3-bar:after {
    content: "";
    display: table;
    clear: both;
}

.w3-col, .w3-half, .w3-third, .w3-twothird, .w3-threequarter, .w3-quarter {
    float: left;
    width: 100%;
}

.w3-table, .w3-table-all {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: table;
}

.w3-table-all {
    border: 1px solid #ccc;
}

.w3-col.s1 {
    width: 8.33333%;
}

.w3-col.s2 {
    width: 16.66666%;
}

.w3-col.s3 {
    width: 24.99999%;
}

.w3-col.s4 {
    width: 33.33333%;
}

.w3-col.s5 {
    width: 41.66666%;
}

.w3-col.s6 {
    width: 49.99999%;
}

.w3-col.s7 {
    width: 58.33333%;
}

.w3-col.s8 {
    width: 66.66666%;
}

.w3-col.s9 {
    width: 74.99999%;
}

.w3-col.s10 {
    width: 83.33333%;
}

.w3-col.s11 {
    width: 91.66666%;
}

.w3-col.s12, .w3-half, .w3-third, .w3-twothird, .w3-threequarter, .w3-quarter {
    width: 99.99999%;
}

@media (min-width:601px) {
.w3-col.m1 {
    width: 8.33333%;
}

.w3-col.m2 {
    width: 16.66666%;
}

.w3-col.m3, .w3-quarter {
    width: 24.99999%;
}

.w3-col.m4, .w3-third {
    width: 33.33333%;
}

.w3-col.m5 {
    width: 41.66666%;
}

.w3-col.m6, .w3-half {
    width: 49.99999%;
}

.w3-col.m7 {
    width: 58.33333%;
}

.w3-col.m8, .w3-twothird {
    width: 66.66666%;
}

.w3-col.m9, .w3-threequarter {
    width: 74.99999%;
}

.w3-col.m10 {
    width: 83.33333%;
}

.w3-col.m11 {
    width: 91.66666%;
}

.w3-col.m12 {
    width: 99.99999%;
}
}

@media (min-width:993px) {
.w3-col.l1 {
    width: 8.33333%;
}

.w3-col.l2 {
    width: 16.66666%;
}

.w3-col.l3, .w3-quarter {
    width: 24.99999%;
}

.w3-col.l4, .w3-third {
    width: 33.33333%;
}

.w3-col.l5 {
    width: 41.66666%;
}

.w3-col.l6, .w3-half {
    width: 49.99999%;
}

.w3-col.l7 {
    width: 58.33333%;
}

.w3-col.l8, .w3-twothird {
    width: 66.66666%;
}

.w3-col.l9, .w3-threequarter {
    width: 74.99999%;
}

.w3-col.l10 {
    width: 83.33333%;
}

.w3-col.l11 {
    width: 91.66666%;
}

.w3-col.l12 {
    width: 99.99999%;
}
}

.w3-content {
max-width: 500px;
margin-left:auto;
margin-right:auto;
height: 100%;
}

.w3-rest {
overflow: hidden;
}

.w3-layout-container, .w3-cell-row {
display: table;
width: 100%;
}

.w3-layout-row {
display: table-row;
}

.w3-layout-cell, .w3-layout-col, .w3-cell {
display: table-cell;
}

.w3-layout-top, .w3-cell-top {
vertical-align: top;
}

.w3-layout-middle, .w3-cell-middle {
vertical-align: middle;
}

.w3-layout-bottom, .w3-cell-bottom {
vertical-align: bottom;
}

.w3-hide {
display: none !important;
}

.w3-show-block, .w3-show {
display: block !important;
}

.w3-show-inline-block {
display: inline-block !important;
}

@media (max-width:600px) {
.w3-modal-content {
    margin: 0 10px;
    width: auto !important;
}

.w3-modal {
    padding-top: 30px;
}

.w3-topnav a {
    display: block;
}

.w3-navbar li:not(.w3-opennav) {
    float: none;
    width: 100% !important;
}

.w3-navbar li.w3-right {
    float: none !important;
}

.w3-topnav .w3-dropdown-hover .w3-dropdown-content, .w3-navbar .w3-dropdown-click .w3-dropdown-content, .w3-navbar .w3-dropdown-hover .w3-dropdown-content, .w3-dropdown-hover.w3-mobile .w3-dropdown-content, .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative;
}

.w3-topnav, .w3-navbar {
    text-align: center;
}

.w3-hide-small {
    display: none !important;
}

.w3-layout-col, .w3-mobile {
    display: block;
    width: 100% !important;
}

.w3-bar-item.w3-mobile, .w3-dropdown-hover.w3-mobile, .w3-dropdown-click.w3-mobile {
    text-align: center;
}

    .w3-dropdown-hover.w3-mobile, .w3-dropdown-hover.w3-mobile .w3-btn, .w3-dropdown-hover.w3-mobile .w3-button, .w3-dropdown-click.w3-mobile, .w3-dropdown-click.w3-mobile .w3-btn, .w3-dropdown-click.w3-mobile .w3-button {
        width: 100%;
    }
}

@media (max-width:768px) {
.w3-modal-content {
    width: 500px;
}

.w3-modal {
    padding-top: 50px;
}
}

@media (min-width:993px) {
.w3-modal-content {
    width: 900px;
}

.w3-hide-large {
    display: none !important;
}

.w3-sidenav.w3-collapse, .w3-sidebar.w3-collapse {
    display: block !important;
}
}

@media (max-width:992px) and (min-width:601px) {
.w3-hide-medium {
    display: none !important;
}
}

@media (max-width:992px) {
.w3-sidenav.w3-collapse, .w3-sidebar.w3-collapse {
    display: none;
}

.w3-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
}

.w3-center {
text-align: center !important;
top: 0px;
left: 0px;
}
