
.main{
    margin:auto auto;
    width:fit-content;
    margin-top:5em;
}

h1{
    text-align: center;
}

.one_line{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:1em;
}

.one_line > div:first-child {
    width:fit-content;
    margin-right:1em;   
}

.one_line > div:nth-child(2){
    width:15em;
    margin-right:1em;
}

.error {
    color:red;
    margin-bottom:1em;
}

.password > div > div:first-child {
    width:10em;
}
