.container{
    position:fixed;
    right:0px;
    min-width:30em;
    top:60px;
    height:calc(100% - 60px);
    background:white;
    box-shadow: -4px 0px 10px #88888888;
    padding:1em;
    display:flex;
    flex-direction: column;
    z-index:1000;
}
.notification{
    display:flex;
    flex-direction:row;
}

.statusIcon{
    margin-right:1em;
}

.notification > div:nth-child(2){
    flex-grow:1;
    display:flex;
    flex-direction: column;
}

.notificationText{
    display:flex;
}

.notificationText > div:first-child{
    flex-grow:1;
    max-width:25em;
}

.notificationText > div:last-child{
    margin-left:auto;
}